const styles = (theme) => ({
  RulesSelection: {
    width: '100%',
    height: '100%',
    fontFamily: 'Targetica, "Helvetica Neue", Helvetica, Arial, sans-serif',
  },
  RulesSelectionHeader: {
    width: '100%',
    margin: '15px 0px',
  },
  RulesSelectionFilter: {
    width: '100%',
    fontSize: '20px',
  },
  RulesMain: {
    width: '60%',
    minWidth: '1000px',
    height: 'calc(100% - 80px)',
    position: 'absolute',
  },
  rulesTableIdentifiers: {
    width: '60%',
    display: 'inline-block',
    margin: 'auto',
  },
  rulesTableStats: {
    width: '40%',
    display: 'inline-flex',
  },
  rulesTableHeaderText: {
    width: '50%',
    textAlign: 'center',
  },
  rulesTableDataText: {
    width: '33%',
    textAlign: 'center',
    margin: 'auto',
  },
  rulesTableDataTitleText: {
    display: 'inline',
  },
  rulesExpansionPanelChild: {
    paddingLeft: '0',
    paddingRight: '0',
    width: '100%',
  },
  expandedRulesTableCard: {
    backgroundColor: '#ccc',
  },
  rulesTCINTableImage: {
    width: '100px',
    marginTop: 'auto',
    marginBottom: 'auto',
    fontSize: '14px',
    marginLeft: '15px',
  },
  rulesTCINTableTitle: {
    width: '30%',
    minWidth: '300px',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: '15px',
    fontSize: '14px',
  },
  rulesTCINTableChart: {
    width: '50%',
    marginTop: 'auto',
    marginBottom: 'auto',
    fontSize: '14px',
    marginLeft: '15px',
  },
  rulesTCINTableTCIN: {
    minWidth: '80px',
    paddingLeft: '10px',
    paddingRight: '10px',
    fontSize: '14px',
    margin: 'auto',
  },
  rulesTableListMain: {
    width: 'calc(100% - 16px)',
    height: 'calc(100% - 80px)',
    position: 'absolute',
  },
  rulesTableListSecondaryButton: {
    left: '10px',
    top: '50%',
    width: '50px',
    transform: 'translateY(-50%)',
  },
  rulesTableListIcon: {
    right: '10px',
    top: '50%',
    position: 'absolute',
    transform: 'translateY(-50%)',
  },
  rulesTableList: {
    display: 'block',
    height: '100%',
  },
  rulesTableHeader: {
    backgroundColor: '#cccccc',
  },
  rulesListTableListWrapper: {
    position: 'absolute',
    width: '100%',
    height: 'calc(100% - 60px)',
    overflowY: 'auto',
  },
  calucaltedHeight: {
    height: 'calc(100% - 80px)',
  },
  saveFilterTitle: {
    backgroundColor: '#3f51b5',
  },
  saveTitleText: {
    color: 'white',
  },
  progressBarInnerText: {
    verticalAlign: 'super',
  },
})

export default styles
