export const SET_RULES = 'SET_RULES'
export const DISPLAY_RULES_CHANGE = 'DISPLAY_RULES_CHANGE'
export const FILTER_CHANGE = 'FILTER_CHANGE'
export const EXPANDED_STATE_CHANGE = 'EXPANDED_STATE_CHANGE'
export const RULES_FILTERED_TCINS_FOUND = 'RULES_FILTERED_TCINS_FOUND'
export const RULE_DELETE_SUCCESS = 'RULE_DELETE_SUCCESS'
export const RULE_FILTER_WARNING = 'RULE_FILTER_WARNING'
export const RULE_SELECTION_RESET_FETCH = 'RULE_SELECTION_RESET_FETCH'
export const RULE_TCIN_LIST_OR_RULE = 'RULE_TCIN_LIST_OR_RULE'
export const SIZE_CHART_RULE_SELECTION_LOADER =
  'SIZE_CHART_RULE_SELECTION_LOADER'
export const RESET_TCIN_RULE_SELECTION = 'RESET_TCIN_RULE_SELECTION'
export const EDIT_MODE_SIZE_CHART_TCIN = 'EDIT_MODE_SIZE_CHART_TCIN'
export const ACTION_TYPE_CRUD_OPP_TCIN = 'ACTION_TYPE_CRUD_OPP_TCIN'
export const SET_SIZECHART_DOWNLOAD_PROGRES = 'SET_SIZECHART_DOWNLOAD_PROGRES'
