import merge from 'lodash/merge'

const commonConfig = {
  auth: {
    authorizationPath: '/auth/oauth/v2/tgt/authorize/ad-pol/1',
    externalAuthorizationPath: '/auth/oauth/v2/tgt/authorize/nla/1',
    logoutPath: '/login/responses/logoff.html',
    redirectUri: `${window.location.origin}/auth/login`,
    responseType: 'token id_token',
    scope: ['openid profile'],
    storageType: 'localStorage',
    tokenType: 'Bearer',
  },
}

const envConfigs = {
  stg: {
    auth: {
      host: 'https://oauth.iam.perf.target.com',
      logoutHost: 'https://logonservices.iam.perf.target.com',
      externalHost: 'https://oauth.iam.perf.partnersonline.com',
      externalLogoutHost: 'https://oauth.iam.perf.partnersonline.com',
      clientId: 'pipeline_npe_im',
      nonce: '1234',
    },
    api: {
      classificationApi: 'https://pipelineclassifyapp-stg.prod.target.com/',
      gatewayKey: '8d783616af4f7906355dfcb9de903868caa7d6fd',
      imageOSphereApi: 'https://imageosphere-stg.prod.target.com/',
      itemApi: 'https://pipelineitemapi-stg.prod.target.com/',
      pipelineAssetApp: 'https://pipelineassetapp-stg.dev.target.com/',
      pipelineContentApp: 'https://pipelinecontentapp-stg.dev.target.com/',
      sceneSevenBaseUrl: 'https://target.scene7.com/is/image/TargetDev/',
      sizeChartApi: 'https://cepsizechartapp-stg.dev.target.com/',
      sizeChartV2Api: 'https://cepsizechartapp-stg.dev.target.com/',
      externalApi: 'https://pipeline-external-app-stg.target.com/',
      reviewExternalApi: 'https://pipelineexternalapp.dev.target.com/',
      externalModelAsset: 'https://externalmodelassetsv1.dev.target.com/',
      altTextApi: 'https://pipelinesearchapi-stg.prod.target.com/',
      aggregator: 'https://pipelineaggregatorapi-stg.dev.target.com/',
    },
    url: {
      appUrl: 'https://pipelineui-stg.prod.target.com/',
      appV1Url: 'https://cepguidesui-stg.prod.target.com',
    },
    redashDashboards: {
      vaultMetrics: 'https://redash.cep.target.com/dashboard/inf-vault-status',
      imageMetrics: 'https://redash.cep.target.com/dashboard/inf-processed',
      textMetrics: 'https://redash.cep.target.com/dashboard/text-content',
    },
    kibanaDashboards: {
      ostpipettcLogs:
        'http://shared.ttc.logging.target.com/goto/247293e5fde0cdbe88524e29633fbd8e',
      ostpipetteLogs:
        'http://shared.tte.logging.target.com/goto/d7d6f6abec0272f3eed6ec80e4e68f4d',
      ostvaultttcLogs:
        'http://shared.ttc.logging.target.com/goto/342b9a951a790aa8764730fb78a4f25b',
      ostvaulttteLogs:
        'http://shared.tte.logging.target.com/goto/fbdd664b2bd194a390519313b2b7643a',
    },
    mongoDashboards: {
      stgcontentdb:
        'https://visualize.target.com/d/000008640/mongodb-overview-telegraph?refresh=1m&orgId=1&var-host=mgmlxrep-contentdbstg2',
      prdcontentdb:
        'https://visualize.target.com/d/000008640/mongodb-overview-telegraph?refresh=1m&orgId=1&var-host=mgmlxrep-contentdbprd4',
      stgvaultdb:
        'https://visualize.target.com/d/000008640/mongodb-overview-telegraph?refresh=1m&orgId=1&var-host=mgmlxrep-dcv3',
      prdvaultdb:
        'https://visualize.target.com/d/000008640/mongodb-overview-telegraph?refresh=1m&orgId=1&var-host=mgmlxrep-dcvprd2',
    },
    dashboard: {
      monitorHost: 'https://pipelinemonitorapi-stg.prod.target.com',
      intakeHost: 'https://pipelinemlapi-stg.prod.target.com/image',
    },
    // image quality standards
    imageStandards: {
      minFileSize: 5000, // 5Kb
      maxFileSize: 100 * 1024 * 1024, // 100Mb
      allowedExtensionsRegex: /(jpg|jpeg|tif|tiff|png|psd)$/i,
      filenameRegex: /^\d{6,10}([_iI]mport){0,1}(?!_00)([_]\d{2}){0,1}$/,
    },
    uploadStandards: {
      maxNumberofFiles: 50,
      maxRetry: 10,
      chunkSize: 1024 * 1024, // 1 MB
      timeout: 10000, // 10sec
    },
    bulkUploadLongCopy: {
      maxNumberofFiles: 251,
    },
    altTexbulkUpload: {
      maxNumberofFiles: 251,
    },
    videoUploadLimit: 209715200, // 200 MB
    targetApiGateway: {
      targetApiGatewayKey: '8d783616af4f7906355dfcb9de903868caa7d6fd',
      targetApiGatewayUrl: 'https://stage-api.target.com/',
      analytics: 'consumers/v1/ingest/internal/internal_app',
      downloadBulkUploadReport:
        'item_assets/v1/long_copy/bulk_upload_report/download',
      generateBulkUploadReport:
        'item_assets/v1/long_copy/bulk_upload_report/generate',
      imageHistory: 'item_assets/v1/external/image/history?page_number=',
      saveJob: 'item_assets/v1/external/job/save_job',
      saveGroupJob: 'item_assets/v1/external/group_job/save_job',
      updateGroupJob: 'item_assets/v1/external/group_job/update_group_assets',
      sessionStartPath: 'vault_digital_assets/v1/chunk_uploads/start',
      sessionUploadPath: 'vault_digital_assets/v1/chunk_uploads',
      sessionEndPath: 'vault_digital_assets/v1/chunk_uploads/end',
      strapiApi:
        'item_assets/v1/cms/documents?application_name=Content%20Pipeline&is_published=true',
      videoHistory:
        'item_assets/v1/external/group_search/filtered_history?emailId=',
      videoHistoryFilter:
        'item_assets/v1/external/group_search/filtered_history/get_all_tcins?emailId=',
    },
    hostName: {
      internalUrl: 'https://cepguidesui-stg.prod.target.com',
      externalUrl: 'https://stg-vendorpipeline.target.com',
      externalUrlV2: 'https://pipeline.perf.partnersonline.com',
    },
  },
  prod: {
    auth: {
      host: 'https://oauth.iam.target.com',
      logoutHost: 'https://logonservices.iam.target.com',
      externalHost: 'https://oauth.iam.partnersonline.com',
      externalLogoutHost: 'https://oauth.iam.partnersonline.com',
      clientId: 'pipeline_prod_im',
      nonce: '1234',
    },
    api: {
      classificationApi: 'https://pipelineclassifyapp.prod.target.com/',
      gatewayKey: '485533e2d75d744c6c3a4abc673b59d4ea507db3',
      imageOSphereApi: 'https://imageosphere.prod.target.com/',
      itemApi: 'https://pipelineitemapi.prod.target.com/',
      pipelineAssetApp: 'https://pipelineassetapp.prod.target.com/',
      pipelineContentApp: 'https://pipelinecontentapp.prod.target.com/',
      sceneSevenBaseUrl: 'https://target.scene7.com/is/image/Target/',
      sizeChartApi: 'https://cepsizechartapp-prd.prod.target.com/',
      sizeChartV2Api: 'https://cepsizechartapp-prd.prod.target.com/',
      externalApi: 'https://pipeline-external-app.target.com/',
      reviewExternalApi: 'https://pipelineexternalapp.prod.target.com/',
      externalModelAsset: 'https://externalmodelassetsv1.prod.target.com/',
      altTextApi: 'https://pipelinesearchapi.prod.target.com/',
      aggregator: 'https://pipelineaggregatorapi.prod.target.com/',
    },
    url: {
      appUrl: 'https://pipelineui.prod.target.com/',
      appV1Url: 'https://cepguidesui.prod.target.com',
    },
    redashDashboards: {
      vaultMetrics: 'https://redash.cep.target.com/dashboard/inf-vault-status',
      imageMetrics: 'https://redash.cep.target.com/dashboard/inf-processed',
      textMetrics: 'https://redash.cep.target.com/dashboard/text-content',
    },
    kibanaDashboards: {
      ostpipettcLogs:
        'http://shared.ttc.logging.target.com/goto/247293e5fde0cdbe88524e29633fbd8e',
      ostpipetteLogs:
        'http://shared.tte.logging.target.com/goto/d7d6f6abec0272f3eed6ec80e4e68f4d',
      ostvaultttcLogs:
        'http://shared.ttc.logging.target.com/goto/342b9a951a790aa8764730fb78a4f25b',
      ostvaulttteLogs:
        'http://shared.tte.logging.target.com/goto/fbdd664b2bd194a390519313b2b7643a',
    },
    mongoDashboards: {
      stgcontentdb:
        'https://visualize.target.com/d/000008640/mongodb-overview-telegraph?refresh=1m&orgId=1&var-host=mgmlxrep-contentdbstg2',
      prdcontentdb:
        'https://visualize.target.com/d/000008640/mongodb-overview-telegraph?refresh=1m&orgId=1&var-host=mgmlxrep-contentdbprd4',
      stgvaultdb:
        'https://visualize.target.com/d/000008640/mongodb-overview-telegraph?refresh=1m&orgId=1&var-host=mgmlxrep-dcv3',
      prdvaultdb:
        'https://visualize.target.com/d/000008640/mongodb-overview-telegraph?refresh=1m&orgId=1&var-host=mgmlxrep-dcvprd2',
    },
    dashboard: {
      monitorHost: 'https://pipelinemonitorapi.prod.target.com',
      intakeHost: 'https://pipelinemlapi.prod.target.com/image',
    },
    // image quality standards
    imageStandards: {
      minFileSize: 5000, // 5Kb
      maxFileSize: 100 * 1024 * 1024, // 100Mb
      allowedExtensionsRegex: /(jpg|jpeg|tif|tiff|png|psd)$/i,
      filenameRegex: /^\d{6,10}([_iI]mport){0,1}(?!_00)([_]\d{2}){0,1}$/,
    },
    uploadStandards: {
      maxNumberofFiles: 50,
      maxRetry: 10,
      chunkSize: 1024 * 1024, // 1 MB
      timeout: 10000, // 10sec
    },
    bulkUploadLongCopy: {
      maxNumberofFiles: 251,
    },
    altTexbulkUpload: {
      maxNumberofFiles: 251,
    },
    videoUploadLimit: 209715200, // 200 MB
    targetApiGateway: {
      targetApiGatewayKey: '485533e2d75d744c6c3a4abc673b59d4ea507db3',
      targetApiGatewayUrl: 'https://api.target.com/',
      analytics: 'consumers/v1/ingest/internal/internal_app',
      downloadBulkUploadReport:
        'item_assets/v1/long_copy/bulk_upload_report/download',
      generateBulkUploadReport:
        'item_assets/v1/long_copy/bulk_upload_report/generate',
      imageHistory: 'item_assets/v1/external/image/history?page_number=',
      saveJob: 'item_assets/v1/external/job/save_job',
      saveGroupJob: 'item_assets/v1/external/group_job/save_job',
      updateGroupJob: 'item_assets/v1/external/group_job/update_group_assets',
      sessionStartPath: 'vault_digital_assets/v1/chunk_uploads/start',
      sessionUploadPath: 'vault_digital_assets/v1/chunk_uploads',
      sessionEndPath: 'vault_digital_assets/v1/chunk_uploads/end',
      strapiApi:
        'item_assets/v1/cms/documents?application_name=Content%20Pipeline&is_published=true',
      videoHistory:
        'item_assets/v1/external/group_search/filtered_history?emailId=',
      videoHistoryFilter:
        'item_assets/v1/external/group_search/filtered_history/get_all_tcins?emailId=',
    },
    hostName: {
      internalUrl: 'https://cepguidesui.prod.target.com',
      externalUrl: 'https://vendorpipeline.target.com',
      externalUrlV2: 'https://pipeline.partnersonline.com',
    },
  },
}

// env.js sets APP_ENV
// const appEnv = process.env.APP_ENV
let appEnv = 'stg'
if (
  window.location.hostname === 'pipeline.target.com' ||
  window.location.hostname === 'vendorpipeline.target.com' ||
  window.location.hostname === 'pipeline.partnersonline.com' ||
  window.location.hostname === 'pipelineui.prod.target.com'
) {
  appEnv = 'prod'
}
// eslint-disable-next-line no-console
console.log(`Pipeline: ${appEnv}`)
const config = envConfigs[appEnv]
const apiConfig = merge(commonConfig, config)

export default apiConfig
