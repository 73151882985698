const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(),
  },
  margin: {
    margin: theme.spacing(2),
  },
  smallMargin: {
    margin: theme.spacing(0.5),
  },
  saveTitleText: {
    color: 'white',
  },
  copyDataroot: {
    width: '100%',
  },
  table: {
    minWidth: '1020px',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  highlight: {
    backgroundColor: '#CC0000',
    color: 'white',
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: 'white',
  },
  progressBar: {
    marginLeft: '50%',
  },
  title: {
    flex: '0 0 auto',
  },
  margin5: {
    margin: '5px',
  },
  backButtonLabel: {
    padding: 0,
    color: '#3f51b5',
    margin: 0,
    fontSize: '14px',
    fontWeight: 500,
  },
  headerLabel: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '500',
    fontStyle: 'normal',
    color: '#CC0000',
    textAlign: 'center',
    padding: '45px 0 0 10px',
    fontSize: '25px',
  },
  helperTextLabel: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: theme.typography.pxToRem(12),
    textAlign: 'left',
    marginTop: 8,
    lineHeight: '1em',
    minHeight: '1em',
    margin: 0,
  },
  maxExceeded: {
    color: 'red',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: theme.typography.pxToRem(12),
    textAlign: 'left',
    marginTop: 8,
    lineHeight: '1em',
    minHeight: '1em',
    margin: 0,
  },
  buttonSaveNote: {
    margin: '15px 15px 0 0',
  },
  selectedImage: {
    maxWidth: '600px',
    maxHeight: '600px',
    border: null,
  },
  gridListImage: {
    width: '120px',
    height: '120px',
    margin: '10px',
    border: '1px solid rgba(0, 15, 46, 1)',
  },
  productDetailWrapper: {
    marginLeft: '10px',
    maxWidth: '400px',
    marginBottom: '20px',
  },
  gridListImageSelected: {
    width: '120px',
    height: '120px',
    margin: '10px -10px 10px 10px',
    border: '2px solid #3f51b5',
  },
  assignToUserSectionDrawer: {
    width: '500px',
  },
  notesSectionDrawer: {
    width: '700px',
  },
  drawerButton: {
    margin: '0px 0px 0px 5px',
    color: 'white',
  },
  marginLeft5: {
    marginLeft: '5px',
  },
  noResult: {
    textAlign: 'center',
    padding: '20px',
  },
  saveFilterWidth: {
    width: '600px',
  },
  marginTop20: {
    marginTop: '20px',
  },
  linkButton: {
    padding: 0,
  },
  productDetailHeader: {
    padding: '5px 0 0 20px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '500',
    fontStyle: 'normal',
    fontSize: '12px',
    color: '#999999',
    lineHeight: '28px',
  },
  productDetailContainer: {
    maxHeight: '40vh',
    paddingLeft: '20px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: '15px',
    color: '#666666',
    lineHeight: '28px',
    overflowY: 'auto',
  },
  sticker: {
    width: '100px',
    height: '100px',
    borderRadius: '15px',
    margin: '15px',
  },
  stickerDisplayName: {
    marginBottom: '15px',
    color: '#1B75D3',
    fontSize: '14px',
  },
  stickerContainer: {
    width: '160px',
    cursor: 'pointer',
  },
  swatchImage: {
    width: '50px',
    height: '50px',
    margin: '10px',
    borderRadius: '10%',
    cursor: 'pointer',
  },
  swatchImageSelected: {
    width: '50px',
    height: '50px',
    margin: '8px 0px 8px 8px',
    padding: '1px',
    border: '2px solid #3f51b5',
    borderRadius: '10% 10% 10% 10%',
    cursor: 'pointer',
  },
  marginBottom20: {
    marginBottom: '20px',
  },
  selectedCheck: {
    color: '#3f51b5',
    position: 'relative',
    left: '56px',
    right: '0px',
    top: '-23px',
  },
  editLongCopy: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: '14px',
    overflowY: 'auto',
    marginTop: '7px',
  },
  editFeatureBulletDiv: {
    marginTop: '16px',
    padding: '19px 8px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    overflowY: 'auto',
    height: '149px',
  },
  editFeatureBulletDivDisable: {
    marginTop: '16px',
    padding: '19px 8px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    overflowY: 'auto',
    height: '149px',
    color: 'rgba(0, 0, 0, 1)',
  },
  counterContainer: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    margin: '36px 0 0 7px',
    border: 'solid 1px #3f51b5',
    borderRadius: '4px',
    padding: '10px 6px',
    color: '#3f51b5',
    height: '89.7%',
  },
  numberCount: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '800',
    fontStyle: 'strong',
    fontSize: '38px',
    margin: '20px 2px',
  },
  boldText: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '600',
    fontStyle: 'strong',
    fontSize: '14px',
    textAlign: 'center',
  },
  headerHighlightText: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '600',
    fontStyle: 'strong',
    fontSize: '18px',
  },
  featureBulletDivUl: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    marginLeft: '20px',
  },
  historyLongCopy: {
    margin: '16px 0 9px 0',
    padding: '19px 14px',
    width: '94%',
    border: '1px solid rgba(0, 0, 0, 1)',
    borderRadius: '4px',
    overflowY: 'auto',
    maxHeight: '300px',
    color: 'rgba(0, 0, 0, 1)',
  },
  historyFeatureBullet: {
    margin: '16px 0 9px 0',
    padding: '19px 6px',
    width: '96%',
    border: '1px solid rgba(0, 0, 0, 1)',
    borderRadius: '4px',
    overflowY: 'auto',
    maxHeight: '300px',
    color: 'rgba(0, 0, 0, 1)',
  },
  historyheaderHighlightText: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '600',
    fontStyle: 'strong',
    fontSize: '18px',
    marginLeft: '5px',
  },
  historyfeaturBulletDivUl: {
    marginLeft: '5px',
  },
  filterTitle: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  marginRight20: {
    marginRight: '20px',
  },
  totalElementsTitle: {
    color: '#CC0000',
    padding: '30px 5px',
    fontSize: '1.2em',
  },
  deleteCopyFeatureTitle: {
    color: '#000000',
    fontSize: '1.2em',
    fontWeight: '600',
  },
  deleteCopyFeatureText: {
    fontSize: '0.8em',
  },
  deleteCopyFeatureConfirmText: {
    color: '#000000',
    fontSize: '1.0em',
  },
  historyButtons: {
    cursor: 'pointer',
    border: '2px solid rgba(56, 94, 166, 1)',
    borderRadius: '5px',
    margin: '7px 0px',
    padding: '5px 10px 10px 10px',
  },
  historyButtonsSelected: {
    cursor: 'pointer',
    backgroundColor: 'rgba(56, 94, 166, 1)',
    color: 'white',
    borderRadius: '5px',
    margin: '7px 0px',
    padding: '5px 10px 10px 10px',
  },
  historyButtonContainer: {
    maxHeight: '275px',
    overflowY: 'auto',
    overflowX: 'auto',
  },
  notesWordCount: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: theme.typography.pxToRem(12),
    textAlign: 'left',
    lineHeight: '1em',
    minHeight: '1em',
    marginRight: '18px',
  },
  marginTop10: {
    marginTop: '10px',
  },
  statusLabel: {
    marginTop: '30px',
    fontfontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: '14px',
    fontWeight: 500,
  },
  noHistoryData: {
    padding: '40px',
    margin: '20px',
  },
  buildFilterExpansion: {
    width: '100%',
    margin: '0 0 5px 0',
  },
  newFilterContainer: {
    marginLeft: '20px',
  },
  minHeightCard: {
    minHeight: '217px',
  },
  paddingLeft20: {
    paddingLeft: '20px',
  },
  productModalContainer: {
    maxHeight: '260px',
    paddingLeft: '20px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: '15px',
    color: '#666666',
    lineHeight: '28px',
    overflowY: 'auto',
  },
  addScrollBar: {
    overflowY: 'auto',
    height: '400px',
    '@media (min-height: 960px)': {
      height: 'auto',
    },
  },
  tcinList: {
    overflowY: 'auto',
    height: '150px',
  },
  saveFilterTitle: {
    backgroundColor: '#3f51b5',
  },

  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  noOptionsMessage: {
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
  sort: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  width100Percent: {
    width: '100%',
  },
  marginBottom5: {
    marginBottom: '5px',
  },
  marginLeft7: {
    margin: '0 0 0 7px',
  },
  height49: {
    height: 49,
  },
  toolBar: {
    position: 'fixed',
    bottom: '0',
    width: '100%',
    backgroundColor: '#CC0000',
    color: 'white',
    zIndex: 1000,
  },
  swatchDiv: {
    width: '60px',
    height: '60px',
    padding: '5px',
  },
  fontSize18: {
    fontSize: '18px',
  },
  selectedCheckIcon: {
    color: '#3f51b5',
    position: 'relative',
    left: '-10px',
    top: '-114px',
  },
  badgesWrapper: {
    maxWidth: '400px',
    overflowX: 'auto',
    marginLeft: '10px',
  },
  inlineFlex: {
    display: 'inline-flex',
  },
  badgeText: {
    textAlign: 'center',
    maxWidth: '75px',
  },
  marginLeftRight10: {
    margin: '0px 10px',
  },
  noteWarning: {
    fontSize: '12px',
    margin: '10px 0',
    color: 'red',
  },
  marginLeft15: {
    marginLeft: '15px',
  },
  noColor: {
    backgroundColor: 'transparent',
  },
  loneColour: {
    display: 'block',
    height: '20px',
    backgroundColor: '#eeee00',
  },
  ltwoColour: {
    display: 'block',
    height: '20px',
    backgroundColor: '#ffa500',
  },
  profaneHeading: {
    fontSize: '12px',
    textAlign: 'left',
    display: 'block',
    fontWeight: 'bold',
  },
  profaneWrap: {
    display: 'inline-Block',
    marginRight: '10px',
  },
  profaneMainWrap: {
    marginLeft: '17px',
  },
  singleEditDisclaimer: {
    color: 'white',
    backgroundColor: '#ffa500',
    paddingLeft: '5px',
    paddingRight: '5px',
    display: 'inline-block',
  },
  singleEditDisclaimerWrapper: {
    marginTop: '10px',
  },
  cardAlignemnet: {
    marginBottom: '20px',
  },
  subjectiveStamentAlign: {
    paddingLeft: '20px',
    margin: '0',
  },
  acnhorAlign: {
    padding: '5px 0 0 20px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '500',
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '28px',
    cursor: 'pointer',
    color: 'blue',
    textDecoration: 'underline',
  },
  removePadding: { padding: '0' },
  productDetailContainerClaimsSec: {
    maxHeight: '15vh',
    paddingLeft: '20px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: '15px',
    color: '#666666',
    lineHeight: '28px',
    overflowY: 'auto',
  },
  autoGenButtonAlign: {
    marginTop: '30px',
  },
  showMoreCenterAlign: {
    textAlign: 'center',
    padding: '20px',
  },
  appBar: {
    backgroundColor: '#CC0000',
  },
})

export default styles
