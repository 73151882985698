import React from 'react'
import { connect } from 'react-redux'
import {
  withStyles,
  Input,
  IconButton,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  DialogContent,
  Button,
} from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import styles from './theme'
import RulesTableList from './Components/RulesTableList'
import TCINTable from './Components/SizeChartTCINTable'
import HeaderTitle from '../Shared/Header/HeaderTitle'
import { Navigate } from 'react-router'
import {
  getRules,
  filterRules,
  expandRuleIndex,
  deleteRule,
  sendWarning,
  createByTcinOrRule,
  sizeChartRuleSelectionLoader,
  resetTcinRuleSelection,
} from '../../store/sizeChart/ruleSelection/actionCreator'
import { setRule } from '../../store/sizeChart/sizeChartEdit/sizeChartDataActions'
import { setRuleName } from '../../store/sizeChart/ruleBuilder/actionCreator'
import RuleOrTcinPopUp from './Components/SizeChartTcinOrRulePopUp'
import { Grid, CircularProgress } from '@material-ui/core'
import { isEmpty } from '../Shared/SharedUtils'
import { SIZE_CHART_LOADING, SIZE_CHART_REFRESHING } from '../Shared/Constants'

export class RuleSelection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: {
        redirect: false,
        target: '',
      },
      popUpFlag: false,
    }
  }

  componentDidMount() {
    this.props.getRules()
    this.props.resetTcinRuleSelection()
  }

  redirect() {
    if (this.state.redirect.redirect) {
      return (
        <Navigate
          to={{
            pathname: this.state.redirect.target,
            state: { refferer: '/v2/size-and-fit' },
          }}
        />
      )
    }
  }

  hasRules(filteredRules, filter) {
    return (
      (filteredRules && Object.keys(filteredRules).length > 0) || filter === ''
    )
  }

  getTCINs(rule) {
    const { rules } = this.props
    return rules[rule.category].brands[rule.brand].sizes[rule.size].rules[
      rule.name
    ].items
  }

  editRule(rule) {
    let existingRuleFlag =
      rule.brand === '' ||
      rule.category === '' ||
      rule.name === '' ||
      rule.size === ''
    this.props.setSizeChart(rule)
    this.props.setRuleName(rule.name, false)
    if (!existingRuleFlag) {
      this.props.sizeChartRuleSelectionLoader(true)
      this.setState({
        redirect: {
          redirect: true,
          target: '/v2/size-and-fit/edit-rule',
        },
      })
    } else {
      this.setState({ popUpFlag: !this.state.popUpFlag })
    }
  }

  createByRuleOrTcin = () => {
    this.setState({
      redirect: {
        redirect: true,
        target: '/v2/size-and-fit/edit-rule',
      },
    })
  }

  cancelCreateByRuleOrTcin = () => {
    this.setState({ popUpFlag: !this.state.popUpFlag })
  }

  render() {
    const {
      classes,
      rules,
      filterRules,
      filteredRules,
      expanded,
      expandRuleIndex,
      searchedTCINs,
      filter,
      warning,
      loader,
    } = this.props
    return (
      <div id="RulesSelection" className={classes.RulesSelection}>
        {this.redirect()}
        <HeaderTitle title="Size and Fit / Rule Selection" />
        <div id="Rules" className={classes.RulesMain}>
          <div id="Rules Header" className={classes.RulesSelectionHeader}>
            <Input
              placeholder="enter category, brand or size or paste TCINs"
              onChange={(e) => filterRules(e.target.value, rules)}
              id="Rules Search"
              className={classes.RulesSelectionFilter}
              value={filter}
              endAdornment={
                <IconButton onClick={() => filterRules('', rules)}>
                  <Clear />
                </IconButton>
              }
            />
          </div>
          <RuleOrTcinPopUp
            popUpFlag={this.state.popUpFlag}
            createByRuleOrTcin={this.createByRuleOrTcin}
            cancelCreateByRuleOrTcin={this.cancelCreateByRuleOrTcin}
            createByTcinOrRule={this.props.createByTcinOrRule}
          />
          <Dialog open={warning.open}>
            <DialogTitle>Are You Sure You Want to do That?</DialogTitle>
            <DialogContent>
              <DialogContentText>{/* {warning.message} */}</DialogContentText>
            </DialogContent>
            <DialogActions>
              {warning.cancel ? (
                <Button
                  onClick={() => warning.cancel()}
                  variant="outlined"
                  color="primary"
                >
                  No
                </Button>
              ) : (
                ''
              )}
              {warning.ok ? (
                <Button
                  onClick={() => warning.ok()}
                  variant="contained"
                  color="primary"
                >
                  Yes
                </Button>
              ) : (
                ''
              )}
            </DialogActions>
          </Dialog>
          <div id="Rules Table" className={classes.calculatedHeight}>
            {searchedTCINs.length === 0 ? (
              <RulesTableList
                rulesTree={
                  Object.keys(filteredRules).length === 0
                    ? rules
                    : filteredRules
                }
                expanded={expanded}
                expandRuleIndex={(key, expanded, level) =>
                  expandRuleIndex(key, expanded, level)
                }
                deletePressed={(rule) =>
                  this.props.sendWarning({
                    ignore: false,
                    open: true,
                    message:
                      'This rule has ' +
                      this.getTCINs(rule) +
                      ' tcins assosiated with it.',
                    ok: () => {
                      this.props.sendWarning({
                        open: false,
                      })
                      this.props.deleteRule(rule).then(() => {
                        this.props.expandRuleIndex('', expanded, 0)
                      })
                    },
                    cancel: () => {
                      this.props.sendWarning({
                        open: false,
                      })
                    },
                  })
                }
                editRule={(rule) => this.editRule(rule)}
                mainStyleClass={{
                  width: '100%',
                  position: 'absolute',
                }}
              />
            ) : (
              <TCINTable
                expanded={expanded}
                expandRuleIndex={(key, expanded, level) =>
                  expandRuleIndex(key, expanded, level)
                }
                tcins={searchedTCINs}
                viewRule={(rule) => this.editRule(rule)}
              />
            )}
          </div>
        </div>
        {loader && (
          <Grid className={classes.progressBar} align="center">
            <CircularProgress className={classes.progressBar} />
            <span className={classes.progressBarInnerText}>
              {isEmpty(rules) ? SIZE_CHART_LOADING : SIZE_CHART_REFRESHING}
            </span>
          </Grid>
        )}
      </div>
    )
  }
}

export const mapStateToProps = (state) => {
  const { layout } = state
  const { headerTitle } = layout
  return {
    headerTitle: headerTitle,
    user: { ...state.user },
    rules: state.sizeChartRuleSelection.rules,
    filteredRules: state.sizeChartRuleSelection.filteredRules,
    expanded: state.sizeChartRuleSelection.expanded,
    filter: state.sizeChartRuleSelection.filter,
    searchedTCINs: state.sizeChartRuleSelection.searchedTCINs,
    warning: state.sizeChartRuleSelection.warning,
    loader: state.sizeChartRuleSelection.sizeChartLoading,
  }
}

export function mapDispatchToProps(dispatch) {
  // these are all the functions which will change what the page looks like
  // in a stateless component, all of these actions SHOULD be handled in props
  return {
    getRules: () => dispatch(getRules()),
    filterRules: (filter, rules) => dispatch(filterRules(filter, rules)),
    expandRuleIndex: (key, expanded, level) =>
      dispatch(expandRuleIndex(key, expanded, level)),
    setSizeChart: (rule) => dispatch(setRule(rule)),
    setRuleName: (name) => dispatch(setRuleName(name)),
    deleteRule: (rule) => dispatch(deleteRule(rule)),
    sendWarning: (warning) => dispatch(sendWarning(warning)),
    createByTcinOrRule: (tcinRuleOrByCategory) =>
      dispatch(createByTcinOrRule(tcinRuleOrByCategory)),
    sizeChartRuleSelectionLoader: (loader) =>
      dispatch(sizeChartRuleSelectionLoader(loader)),
    resetTcinRuleSelection: () => dispatch(resetTcinRuleSelection()),
  }
}

RuleSelection.defaultProps = {
  rules: {},
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RuleSelection))
