import {
  RULES_FILTERED_TCINS_FOUND,
  SET_RULES,
  EXPANDED_STATE_CHANGE,
  RULE_FILTER_WARNING,
  FILTER_CHANGE,
  RULE_SELECTION_RESET_FETCH,
  RULE_TCIN_LIST_OR_RULE,
  SIZE_CHART_RULE_SELECTION_LOADER,
  RESET_TCIN_RULE_SELECTION,
  ACTION_TYPE_CRUD_OPP_TCIN,
  SET_SIZECHART_DOWNLOAD_PROGRES,
} from './actionType'
import envConfigs from '../../../config/apiConfig'
import axios from 'axios'
import { toast } from 'react-toastify'
import { TOAST_BODY } from '../../../components/Shared/Constants'

export function resetFetch(data) {
  return {
    type: RULE_SELECTION_RESET_FETCH,
    payload: {
      isFetching: data,
    },
  }
}

export function sizeChartLoading(data) {
  return {
    type: SET_SIZECHART_DOWNLOAD_PROGRES,
    payload: {
      sizeChartLoading: data,
    },
  }
}

export function getRules() {
  return (dispatch) => {
    dispatch(sizeChartLoading(true))
    axios
      .get(
        envConfigs.api.sizeChartApi +
          'rules/get/all?key=' +
          envConfigs.api.gatewayKey
      )
      .then((response) => {
        let rules = response.data
        // TODO: sort in backend, not here
        // sort the rules alphabetically
        if (response.data) {
          dispatch(sizeChartLoading(false))
        }
        rules.sort((a, b) => {
          if (a.size_chart_info === null) {
            return -1
          }
          if (b.size_chart_info === null) {
            return 1
          }
          if (a.size_chart_info.category === b.size_chart_info.category) {
            if (a.size_chart_info.brand === b.size_chart_info.brand) {
              if (a.size_chart_info.size === b.size_chart_info.size) {
                return a.name <= b.name ? -1 : 1
              }
              return a.size_chart_info.size < b.size_chart_info.size ? -1 : 1
            }
            return a.size_chart_info.brand < b.size_chart_info.brand ? -1 : 1
          }
          return a.size_chart_info.category < b.size_chart_info.category
            ? -1
            : 1
        })

        let outputTree = {}
        for (let i = 0; i < rules.length; i++) {
          if (rules[i].size_chart_info !== null) {
            let rule = {
              ...rules[i].size_chart_info,
              name:
                rules[i].name !== undefined && rules[i].name !== null
                  ? rules[i].name
                  : rules[i].id,
              items: rules[i].tcins === null ? 0 : rules[i].tcins,
              ruleId: rules[i].id,
            }
            if (outputTree[rule.category] === undefined) {
              outputTree[rule.category] = {
                items: rule.items,
                brands: {},
                ruleCount: 1,
              }
              outputTree[rule.category].brands[rule.brand] = {
                items: rule.items,
                ruleCount: 1,
                sizes: {},
              }
              outputTree[rule.category].brands[rule.brand].sizes[rule.size] = {
                items: rule.items,
                ruleCount: 1,
                rules: {},
              }
              outputTree[rule.category].brands[rule.brand].sizes[
                rule.size
              ].rules[rule.name] = {
                ruleId: rule.ruleId,
                items: rule.items,
              }
            } else {
              outputTree[rule.category].items += rule.items
              outputTree[rule.category].ruleCount += 1
              if (outputTree[rule.category].brands[rule.brand] === undefined) {
                outputTree[rule.category].brands[rule.brand] = {
                  items: rule.items,
                  ruleCount: 1,
                  sizes: {},
                }
                outputTree[rule.category].brands[rule.brand].sizes[rule.size] =
                  {
                    items: rule.items,
                    ruleCount: 1,
                    rules: {},
                  }
                outputTree[rule.category].brands[rule.brand].sizes[
                  rule.size
                ].rules[rule.name] = {
                  ruleId: rule.ruleId,
                  items: rule.items,
                }
              } else {
                outputTree[rule.category].brands[rule.brand].items += rule.items
                outputTree[rule.category].brands[rule.brand].ruleCount += 1
                if (
                  outputTree[rule.category].brands[rule.brand].sizes[
                    rule.size
                  ] === undefined
                ) {
                  outputTree[rule.category].brands[rule.brand].sizes[
                    rule.size
                  ] = {
                    items: rule.items,
                    ruleCount: 1,
                    rules: {},
                  }
                  outputTree[rule.category].brands[rule.brand].sizes[
                    rule.size
                  ].rules[rule.name] = {
                    ruleId: rule.ruleId,
                    items: rule.items,
                  }
                } else {
                  outputTree[rule.category].brands[rule.brand].sizes[
                    rule.size
                  ].items += rule.items
                  outputTree[rule.category].brands[rule.brand].sizes[
                    rule.size
                  ].ruleCount += 1
                  outputTree[rule.category].brands[rule.brand].sizes[
                    rule.size
                  ].rules[rule.name] = {
                    ruleId: rule.ruleId,
                    items: rule.items,
                  }
                }
              }
            }
          }
        }
        dispatch(setRules(outputTree))
      })
      .catch((error, data) => {
        dispatch(sizeChartLoading(false))
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function searchTcins(tcins) {
  return (dispatch) => {
    let out = []
    axios
      .get(
        envConfigs.api.sizeChartV2Api +
          `sizechart/search/v1/find_all_sizechart_info?tcin=${tcins[0]}&key=` +
          envConfigs.api.gatewayKey
      )
      .then((response) => {
        let results = response.data
        axios
          .get(
            envConfigs.api.itemApi +
              'digital_items/v1/lite/' +
              tcins[0] +
              '?key=' +
              envConfigs.api.gatewayKey
          )
          .then((response) => {
            out.push({
              tcin: tcins[0],
              primaryImageGuid:
                response.data.enrichment &&
                response.data.enrichment.images &&
                response.data.enrichment.images.primary_image,
              rules: results.all_applicable_rules
                ? results.all_applicable_rules
                : [],
              title:
                response.data.product_description &&
                response.data.product_description.title
                  ? response.data.product_description.title
                  : '',
            })
          })
          .catch((error, data) => {
            dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
          })
          .then(() => {
            return dispatch({
              type: RULES_FILTERED_TCINS_FOUND,
              tcins: out.slice(0),
            })
          })
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

export function deleteRule(rule) {
  return (dispatch) => {
    return axios
      .post(
        envConfigs.api.sizeChartApi +
          'rules/delete?key=' +
          envConfigs.api.gatewayKey,
        {
          size_chart_info: {
            category: rule.category,
            brand: rule.brand,
            size: rule.size,
          },
          name: rule.name,
        }
      )
      .then(() => {
        window.location.reload()
      })
      .catch((error, data) => {
        dispatch(resetFetch(false), toast.error(error.message, TOAST_BODY))
      })
  }
}

function setRules(rules) {
  return {
    type: SET_RULES,
    rules: rules,
  }
}

export function filterRules(filterText, displayRules) {
  /*
   * This regex is only matching single TCINs but keeping this and underlying
   * methods as a list of TCINs as this will be fixed in a future enhancement
   * Note the underlying function is just taking the first TCIN in the list however
   */
  let regex = /^[0-9]{5,10}$/g
  let matches = filterText.match(regex)
  if (matches !== null && matches.length > 0) {
    let tcins = []
    for (let match in matches) {
      tcins.push(matches[match])
    }
    return (dispatch) => {
      dispatch({
        type: FILTER_CHANGE,
        filter: filterText,
        filteredRules: displayRules,
      })
      dispatch(searchTcins(tcins))
    }
  } else {
    return (dispatch) => {
      let outputRules = {}
      let filters = filterText.split(' ')
      /*
       * The Large Amount of for loops were used instead of a map function
       * because the rules for the filter page are stored in an assosiative
       * array to cut down on search time, which means that a map function
       * won't return the correct type of array, since .map returns a list
       * instead of an object
       */
      // For each filter that was searched on
      for (let i in filters) {
        let filter = filters[i]
        // Check the category level
        for (let categoryName in displayRules) {
          let category = displayRules[categoryName]
          if (categoryName.indexOf(filter) >= 0) {
            // if the category name matches, keep the entire category
            outputRules[categoryName] = category
          } else {
            let outCategory = {
              items: 0,
              ruleCount: 0,
              brands: {},
            }
            // Check the brand Level
            for (let brandName in category.brands) {
              let brand = category.brands[brandName]
              if (brandName.indexOf(filter) >= 0) {
                // If the Brand Name Matches, keep the entire brand
                outCategory.ruleCount += brand.ruleCount
                outCategory.items += brand.items
                outCategory.brands[brandName] = { ...brand }
              } else {
                let outBrand = {
                  items: 0,
                  ruleCount: 0,
                  sizes: {},
                }
                // Check the Size Level
                for (let sizeName in brand.sizes) {
                  let size = brand.sizes[sizeName]
                  if (sizeName.indexOf(filter) >= 0) {
                    // If the Size Name Matches, keep the entire size
                    outBrand.items += size.items
                    outBrand.ruleCount += size.ruleCount
                    outBrand.sizes[sizeName] = { ...size }
                  } else {
                    let outSize = {
                      items: 0,
                      ruleCount: 0,
                      rules: {},
                    }
                    // Check each rule
                    for (let ruleName in size.rules) {
                      let rule = size.rules[ruleName]
                      if (ruleName.indexOf(filter) >= 0) {
                        // Only Keep the Rule if the Rule Name matches the filter
                        outSize.items += rule.items
                        outSize.ruleCount += 1
                        outSize.rules[ruleName] = { ...rule }
                      }
                    }
                    if (outSize.ruleCount > 0) {
                      // if there were rules kept from this size, keep the size too
                      outBrand.items += outSize.items
                      outBrand.ruleCount += outSize.ruleCount
                      outBrand.sizes[sizeName] = { ...outSize }
                    }
                  }
                }
                if (outBrand.ruleCount > 0) {
                  // if there were sizes kept for this brand, keep the brand too
                  outCategory.items += outBrand.items
                  outCategory.ruleCount += outBrand.ruleCount
                  outCategory.brands[brandName] = { ...outBrand }
                }
              }
            }
            if (outCategory.ruleCount > 0) {
              // if there were brands kept for this category, keep the category too
              outputRules[categoryName] = outCategory
            }
          }
        }
      }
      return dispatch(changeFilteredRules(outputRules, filterText))
    }
  }
}

export function expandRuleIndex(key, expanded, level) {
  return (dispatch) => {
    let newExpanded = expanded.slice(0, level)
    if (newExpanded.length < level + 1) {
      newExpanded.push(-1)
    }
    newExpanded[level] = key
    return dispatch({
      type: EXPANDED_STATE_CHANGE,
      expanded: newExpanded,
    })
  }
}

export function sendWarning(warning) {
  return (dispatch) => {
    dispatch({
      type: RULE_FILTER_WARNING,
      warning: warning,
    })
  }
}

function changeFilteredRules(newRules, filter) {
  return {
    type: FILTER_CHANGE,
    filteredRules: newRules,
    filter: filter,
  }
}

export function createByTcinOrRule(tcinOrRule) {
  return {
    type: RULE_TCIN_LIST_OR_RULE,
    selectedTcinRule: tcinOrRule === 'Tcin List',
    actionTypeForTcin: tcinOrRule === 'Tcin List' ? 'Create' : '',
  }
}

export function sizeChartRuleSelectionLoader(payload) {
  return {
    type: SIZE_CHART_RULE_SELECTION_LOADER,
    payloadProp: payload,
  }
}

export function resetTcinRuleSelection() {
  return {
    type: RESET_TCIN_RULE_SELECTION,
  }
}

export function actionTypeCrudOpp(payload) {
  return {
    type: ACTION_TYPE_CRUD_OPP_TCIN,
    actionTypeForTcin: payload,
  }
}
