import { each, includes, isNil, flatten, some } from 'lodash'
import { RELATIONSHIP_TYPE_CODE, VIDEO_APPROVAL_STATUS } from './Constants'
import ColorThief from 'colorthief/dist/color-thief.umd.js'
import {
  startOfDay,
  addDays,
  endOfISOWeek,
  setDay,
  endOfDay,
} from 'date-fns/fp'
import { flow } from 'lodash'

export const defaultTimezone = 'America/Chicago'

export const lwoDiscFunc = (item) => {
  return item && item.includes('L2')
}

export const loneDiscFunc = (item) => {
  return item && item.includes('L1')
}

export const weeksAgoOutFilter = (
  weeks,
  selectedFilterValue,
  selectedFilter,
  buildFilter
) => {
  if (weeks.startDate !== null && weeks.endDate === null) {
    selectedFilterValue = {
      display: `${weeks.startDate.label} - ${getRelativeStartDate(
        weeks.startDate.value,
        true
      )}/${getRelativeEndDate(weeks.startDate.value, true)}`,
      value: {
        weeksFilter: [
          {
            startDate: getRelativeStartDate(weeks.startDate.value, false),
            endDate: getRelativeEndDate(weeks.startDate.value, false),
          },
        ],
      },
    }
  }
  if (weeks.startDate !== null && weeks.endDate !== null) {
    let actualStartWeek = weeks.startDate.value
    let actualEndWeek = weeks.endDate.value
    if (weeks.startDate.value > weeks.endDate.value) {
      actualStartWeek = weeks.endDate.value
      actualEndWeek = weeks.startDate.value
    }

    selectedFilterValue = {
      display: `${weeks.startDate.label}  ${getRelativeStartDate(
        actualStartWeek,
        true
      )} to ${weeks.endDate.label}  ${getRelativeEndDate(actualEndWeek, true)}`,
      value: {
        weeksFilter: [
          {
            startDate: getRelativeStartDate(actualStartWeek, false),
            endDate: getRelativeEndDate(actualEndWeek, false),
          },
        ],
      },
    }
  }

  buildFilter(selectedFilter, selectedFilterValue)
}

export const getRelativeStartDate = (weekOffset, dateTypeDisplay) => {
  // Determine the beginning of the week (Sunday at 12AM)
  const calculatedDate = flow(
    endOfISOWeek(),
    startOfDay(),
    addDays(weekOffset * 7 - 1),
    setDay(0) // Sunday
  )(new Date())

  if (dateTypeDisplay) {
    return convertToDDMMYYYY(calculatedDate)
  } else {
    return calculatedDate.getTime()
  }
}

export const getRelativeEndDate = (weekOffset, dateTypeDisplay) => {
  // Determine the end of the week (Saturday at 11:59PM)
  const calculatedDate = flow(
    endOfISOWeek(),
    endOfDay(),
    addDays(weekOffset * 7 - 1),
    setDay(6) // Saturday
  )(new Date())

  if (dateTypeDisplay) {
    return convertToDDMMYYYY(calculatedDate)
  } else {
    return calculatedDate.getTime()
  }
}

export function convertToDDMMYYYY(str) {
  var date = new Date(str),
    month = ('0' + (date.getMonth() + 1)).slice(-2),
    day = ('0' + date.getDate()).slice(-2)
  return [month, day, date.getFullYear()].join('-')
}

export function regexSelector(type, e) {
  if (type === 'number') {
    e.target.value = e.target.value.replace(/[^0-9]/g, '')
    return e.target.value
  }
  return e.target.value
}

export function isExternalUser(userInfo) {
  let isExternalUser = false
  each(userInfo.memberOf, function (group) {
    var upperGroup = group.toUpperCase()
    if (includes(upperGroup, 'APP-CEP-EXTVENDOR-UPLOAD')) {
      isExternalUser = true
    }
    if (includes(upperGroup, 'APP-CEP-MARKETING-PARTNER')) {
      isExternalUser = true
    }
    if (includes(upperGroup, 'POL-MULTI-PARTNER')) {
      isExternalUser = true
    }
  })
  return isExternalUser
}

export function validateSingleTcin(tcin) {
  return /^[0-9]{5,10}$/.test(tcin)
}

export function mapToArray(source) {
  let array = []
  source.forEach((item) => {
    if (item.value) {
      array.push(item.value)
    }
  })
  return array
}

export const hasWhiteSpace = (s) => {
  return /\s/g.test(s)
}

export const validTcin = (tcin) => {
  var isValid = false
  var validationRegexs = ['^[0-9]{5,10}$', '^B[0-9]{10,10}$"']
  each(validationRegexs, function (regex) {
    // @ts-ignore
    regex = new RegExp(regex)
    if (tcin.match(regex) !== null && tcin.match(regex)[0] === tcin) {
      isValid = true
    }
  })

  return isValid
}

export const validDpci = (dpci) => {
  var isValid = false
  var validationRegexs = [/^\d{1,3}-\d{1,2}-\d{1,4}$/]
  each(validationRegexs, function (regex) {
    // @ts-ignore
    regex = new RegExp(regex)
    if (dpci.match(regex) !== null && dpci.match(regex)[0] === dpci) {
      isValid = true
    }
  })

  return isValid
}

export const splitDpci = (tcin) => {
  let dpcis = tcin.split('\n')
  let dpciList = []
  if (dpcis.length > 0) {
    dpcis.forEach((element) => {
      if (element.trim() !== '') {
        if (element.indexOf(',') > -1) {
          element.split(',').forEach((item) => {
            if (item.trim() !== '') {
              if (validDpci(item.trim())) {
                if (dpciList.indexOf(item.trim()) === -1) {
                  dpciList.push(item.trim())
                }
              }
            }
          })
        } else if (hasWhiteSpace(element)) {
          element.split(' ').forEach((item) => {
            if (item.trim() !== '') {
              if (validDpci(item.trim())) {
                if (dpciList.indexOf(item.trim()) === -1) {
                  dpciList.push(item.trim())
                }
              }
            }
          })
        } else {
          if (validDpci(element.trim())) {
            if (dpciList.indexOf(element.trim()) === -1) {
              dpciList.push(element.trim())
            }
          }
        }
      }
    })
  }
  return dpciList
}

export const splitTcins = (tcin) => {
  let tcins = tcin.split('\n')
  let tcinList = []
  if (tcins.length > 0) {
    tcins.forEach((element) => {
      if (element.trim() !== '') {
        if (element.indexOf(',') > -1) {
          element.split(',').forEach((item) => {
            if (item.trim() !== '') {
              if (validTcin(item.trim())) {
                if (tcinList.indexOf(item.trim()) === -1) {
                  tcinList.push(item.trim())
                }
              }
            }
          })
        } else if (hasWhiteSpace(element)) {
          element.split(' ').forEach((item) => {
            if (item.trim() !== '') {
              if (validTcin(item.trim())) {
                if (tcinList.indexOf(item.trim()) === -1) {
                  tcinList.push(item.trim())
                }
              }
            }
          })
        } else {
          if (validTcin(element.trim())) {
            if (tcinList.indexOf(element.trim()) === -1) {
              tcinList.push(element.trim())
            }
          }
        }
      }
    })
  }
  return tcinList
}

let today = new Date()
let year = today.getFullYear()
let month =
  (today.getMonth() + 1).toString().length === 2
    ? (today.getMonth() + 1).toString()
    : '0' + (today.getMonth() + 1).toString()
let day =
  today.getDate().toString().length === 2
    ? today.getDate().toString()
    : '0' + today.getDate().toString()
let hour =
  today.getHours().toString().length === 2
    ? today.getHours().toString()
    : '0' + today.getHours().toString()
let minutes =
  today.getMinutes().toString().length === 2
    ? today.getMinutes().toString()
    : '0' + today.getMinutes().toString()
let seconds =
  today.getSeconds().toString().length === 2
    ? today.getSeconds().toString()
    : '0' + today.getSeconds().toString()
export const DownloadDate =
  year + '-' + month + '-' + day + 'T' + hour + '-' + minutes + '-' + seconds

export const isValidVideoStatus = (approvalStatus) => {
  if (
    approvalStatus !== VIDEO_APPROVAL_STATUS.IN_PROCESS &&
    approvalStatus !== VIDEO_APPROVAL_STATUS.RETRY &&
    approvalStatus !== VIDEO_APPROVAL_STATUS.APPROVED &&
    approvalStatus !== VIDEO_APPROVAL_STATUS.REJECTED &&
    approvalStatus !== VIDEO_APPROVAL_STATUS.PENDING
  ) {
    return false
  } else return true
}

export const flattenImages = (imageDataChanges, email) => {
  return imageDataChanges.map((item) => {
    return flattenImagesForTcin(item, email)
  })
}

export const imageMapping = (image, email) => {
  return {
    imageId: image.imageId,
    sourceId: image.sourceId ? image.sourceId : image.imageId,
    viewType: image.viewType,
    sequence: image.sequence,
    baseUrl: image.baseUrl,
    publishUrl: image.publishUrl,
    active: image.active,
    tcinsIncluded: image.tcinsIncluded ? image.tcinsIncluded : null,
    labels: image.labels ? image.labels : null,
    lastUpdatedBy: email,
    locked: image.locked,
  }
}

export const flattenImagesForTcin = (item, email) => {
  let primaryImageData = item.rows.primary.images[0]
  let primaryImage = primaryImageData
    ? imageMapping(primaryImageData, email)
    : []
  let alternateImages = item.rows.alternate.images.map((image) => {
    return imageMapping(image, email)
  })
  let disabledImages = item.rows.disabled.images.map((image) => {
    return imageMapping(image, email)
  })
  let swatchImage = item.swatchImage
    ? imageMapping(item.swatchImage, email)
    : []

  return {
    tcin: item.tcin,
    imageList: flatten([
      primaryImage,
      alternateImages,
      disabledImages,
      swatchImage,
    ]),
  }
}

export const sequenceToString = (index) => {
  return index < 9 ? `0${index + 1}` : `${index + 1}`
}

export const requestedPositionToString = (index) => {
  return index < 10 ? `0${index}` : `${index}`
}

export const isSwatchEligible = (relationship) => {
  return (
    relationship === RELATIONSHIP_TYPE_CODE.VC ||
    relationship === RELATIONSHIP_TYPE_CODE.VAP ||
    relationship === RELATIONSHIP_TYPE_CODE.VPC
  )
}

export const determineRelationshipTypeCode = (relationshipTypeCodes) => {
  const filteredRelationshipTypes = relationshipTypeCodes.filter(
    (relationshipTypeCode) =>
      relationshipTypeCode !== RELATIONSHIP_TYPE_CODE.FCMP
  )
  if (some(filteredRelationshipTypes)) {
    return filteredRelationshipTypes[0]
  }
  return RELATIONSHIP_TYPE_CODE.NA
}

export const urlBuilder = (image) => {
  return !isNil(image) ? image.baseUrl + image.publishUrl : ''
}

export const urlExtenSionBuilder = (image) => {
  return !isNil(image) && image.publishUrl.includes('?')
    ? '&hei=40&wei=40'
    : '?hei=40&wei=40'
}

export const colorThiefFromCanvas = (src) => {
  return new Promise((resolve) => {
    // eslint-disable-next-line no-undef
    const img = new Image()
    img.src = src
    img.onload = () => {
      const colorthief = new ColorThief()
      resolve(colorthief.getPalette(img, 9))
    }
  })
}

export const chipFilter = (
  items,
  selectedFilterValue,
  selectedFilter,
  buildFilter
) => {
  let selectedChips = []
  items.forEach((item) => {
    selectedFilterValue = {
      display: item.display,
      value: item.value,
    }
    selectedChips.push(selectedFilterValue)
  })

  buildFilter(selectedFilter, selectedChips)
}

export const dropDownListFilter = (
  ApprovalStatuses,
  approvalStatus,
  selectedFilter,
  buildFilter
) => {
  let selectedFilterValue = ApprovalStatuses.filter(
    (item) => item.value === approvalStatus
  )[0]

  buildFilter(selectedFilter, selectedFilterValue)
}

export const dateRangeFilter = (
  filterValues,
  selectedFilterValue,
  selectedFilter,
  buildFilter,
  isBulkUploadReport
) => {
  if (
    filterValues.dateRange === 'datePicker' &&
    (filterValues.fromDate || filterValues.toDate)
  ) {
    let displayToDate = new Date(filterValues.toDate)
    let formattedToDate = `${
      displayToDate.getMonth() + 1
    }/${displayToDate.getDate()}/${displayToDate.getFullYear()}`
    let displayFromDate = new Date(filterValues.fromDate)
    let formattedFromDate = `${
      displayFromDate.getMonth() + 1
    }/${displayFromDate.getDate()}/${displayFromDate.getFullYear()}`
    selectedFilterValue = {
      display: `${selectedFilter?.display}:${formattedFromDate} - ${formattedToDate} `,
      value: { fromDate: filterValues.fromDate, toDate: filterValues.toDate },
    }
  }
  if (filterValues.dateRange === 'last52Weeks') {
    selectedFilterValue = {
      display: 'Last 52 Weeks',
      value: { duration: 365 },
    }
  }
  if (filterValues.dateRange === 'last24Hours') {
    selectedFilterValue = {
      display: 'Last 24 Hours',
      value: { duration: 1 },
    }
  }
  if (filterValues.dateRange === 'last3Days') {
    selectedFilterValue = {
      display: 'Last 3 Days',
      value: { duration: 3 },
    }
  }
  if (filterValues.dateRange === 'last7Days') {
    selectedFilterValue = {
      display: 'Last 7 Days',
      value: { duration: 7 },
    }
  }
  if (filterValues.dateRange === 'last14Days') {
    selectedFilterValue = {
      display: 'Last 14 Days',
      value: { duration: 14 },
    }
  }
  if (filterValues.dateRange === 'last30Days') {
    selectedFilterValue = {
      display: 'Last 30 Days',
      value: { duration: 30 },
    }
  }
  let startDate = new Date()
  if (isBulkUploadReport) {
    startDate.setDate(startDate.getDate() - 1)
  }
  let formattedToDate = `${
    startDate.getMonth() + 1
  }/${startDate.getDate()}/${startDate.getFullYear()}`
  let toDate = new Date(`${formattedToDate} 23:59:59`)
  if (filterValues.dateRange === 'today') {
    let fromDate = new Date(`${formattedToDate} 00:00:00`)
    selectedFilterValue = {
      display: `${formattedToDate}`,
      value: { fromDate: fromDate.getTime(), toDate: toDate.getTime() },
    }
  }
  if (filterValues.dateRange === 'yesterday') {
    let fromDate = new Date(`${formattedToDate} 00:00:00`)
    selectedFilterValue = {
      display: `${formattedToDate}`,
      value: { fromDate: fromDate.getTime(), toDate: toDate.getTime() },
    }
  }
  if (filterValues.dateRange === 'past3Days') {
    let fromDate = new Date(`${formattedToDate} 00:00:00`)
    fromDate.setDate(fromDate.getDate() - 2)
    let formattedFromDate = `${
      fromDate.getMonth() + 1
    }/${fromDate.getDate()}/${fromDate.getFullYear()}`
    selectedFilterValue = {
      display: `${formattedFromDate} - ${formattedToDate}`,
      value: { fromDate: fromDate.getTime(), toDate: toDate.getTime() },
    }
  }
  if (filterValues.dateRange === 'past7Days') {
    let fromDate = new Date(`${formattedToDate} 00:00:00`)
    fromDate.setDate(fromDate.getDate() - 6)
    let formattedFromDate = `${
      fromDate.getMonth() + 1
    }/${fromDate.getDate()}/${fromDate.getFullYear()}`
    selectedFilterValue = {
      display: `${formattedFromDate} - ${formattedToDate}`,
      value: { fromDate: fromDate.getTime(), toDate: toDate.getTime() },
    }
  }
  if (filterValues.dateRange === 'past14Days') {
    let fromDate = new Date(`${formattedToDate} 00:00:00`)
    fromDate.setDate(fromDate.getDate() - 13)
    let formattedFromDate = `${
      fromDate.getMonth() + 1
    }/${fromDate.getDate()}/${fromDate.getFullYear()}`
    selectedFilterValue = {
      display: `${formattedFromDate} - ${formattedToDate}`,
      value: { fromDate: fromDate.getTime(), toDate: toDate.getTime() },
    }
  }
  if (filterValues.dateRange === 'past30Days') {
    let fromDate = new Date(`${formattedToDate} 00:00:00`)
    fromDate.setDate(fromDate.getDate() - 29)
    let formattedFromDate = `${
      fromDate.getMonth() + 1
    }/${fromDate.getDate()}/${fromDate.getFullYear()}`
    selectedFilterValue = {
      display: `${formattedFromDate} - ${formattedToDate}`,
      value: { fromDate: fromDate.getTime(), toDate: toDate.getTime() },
    }
  }

  buildFilter(selectedFilter, selectedFilterValue)
}

export const deptClassFilter = (
  deptClass,
  selectedFilterValue,
  selectedFilter,
  buildFilter
) => {
  if (deptClass.dept !== -1 && deptClass.class === -1) {
    selectedFilterValue = {
      display: deptClass.dept.type + ' - ' + deptClass.dept.deptName,
      value: {
        merchandise_hierarchies: [
          { department: deptClass.dept.type, class_ids: [] },
        ],
      },
    }
  }
  if (deptClass.dept !== -1 && deptClass.class !== -1) {
    selectedFilterValue = {
      display: `${deptClass.dept.type} - ${deptClass.dept.deptName} / ${deptClass.class.type} - ${deptClass.class.subClassName}`,
      value: {
        merchandise_hierarchies: [
          {
            department: deptClass.dept.type,
            class_ids: [deptClass.class.type],
          },
        ],
      },
    }
  }

  buildFilter(selectedFilter, selectedFilterValue)
}

export const gridCheckedLogic = (selectedItems, checkedItem) => {
  const selectedIndex = indexOfObject(selectedItems, checkedItem)
  let newSelected = []

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selectedItems, checkedItem)
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selectedItems.slice(1))
  } else if (selectedIndex === selectedItems.length - 1) {
    newSelected = newSelected.concat(selectedItems.slice(0, -1))
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selectedItems.slice(0, selectedIndex),
      selectedItems.slice(selectedIndex + 1)
    )
  }
  return newSelected
}

export const indexOfObject = (selecteData, data) => {
  var ok
  for (var i = 0; i < selecteData.length; i++) {
    ok = true
    for (var k in data) {
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty(k)) {
        if (selecteData[i][k] !== data[k]) {
          ok = false
          break
        }
      }
    }
    if (ok) return i
  }
  return -1 // no match
}

export function escapeHtml(input) {
  return { __html: input }
}

export const urlBuilderAltText = (image) => {
  return !isNil(image) ? image.base_url + image.publish_url : ''
}

export function isEmpty(obj) {
  return Object.keys(obj).length === 0
}
